<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ product.name }}</td>
    <td>{{ product.sku }}</td>
    <td class="border">
      <div class="input-group input-group-sm">
        <input type="number" min="0" v-model="available_stock" @input="updateChanges" class="form-control mr-2"
          placeholder="Enter a number" aria-label="Number input" required />
      </div>
    </td>
    <td class="border">
      <div class="input-group input-group-sm">
        <input type="number" min="0" v-model="default_quantity" @input="updateChanges" class="form-control mr-2"
          placeholder="Enter a number" aria-label="Number input" required />
      </div>
    </td>
    <td class="border">
      <div class="btn-group btn-group-toggle">
        <label class="btn btn-sm"
          :class="[is_enabled == 1 ? 'btn-success' : 'btn-secondary', { active: is_enabled == 1 }]">
          <input type="radio" v-model="is_enabled" @change="updateChanges" value="1" />
          Yes
        </label>
        <label class="btn btn-sm"
          :class="[is_enabled == 0 ? 'btn-success' : 'btn-secondary', { active: is_enabled == 0 }]">
          <input type="radio" v-model="is_enabled" @change="updateChanges" value="0" />
          No
        </label>
      </div>
    </td>
    <td class="border">
      <div class="btn-group btn-group-toggle">
        <label class="btn btn-sm" :class="[is_new == 1 ? 'btn-success' : 'btn-secondary', { active: is_new == 1 }]">
          <input type="radio" v-model="is_new" @change="updateChanges" value="1" />
          Yes
        </label>
        <label class="btn btn-sm" :class="[is_new == 0 ? 'btn-success' : 'btn-secondary', { active: is_new == 0 }]">
          <input type="radio" v-model="is_new" @change="updateChanges" value="0" />
          No
        </label>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['index', 'product'],
  data() {
    return {
      available_stock: 0,
      default_quantity: 0,
      is_enabled: 0,
      is_new: 0,
      changes: {},
    };
  },
  mounted() {
      this.available_stock = this.product.stock ? this.product.stock.available_stock : null
      this.default_quantity = this.product.stock ? this.product.stock.default_quantity : null
      this.is_enabled = this.product.stock ? this.product.stock.is_enabled : null
      this.is_new = this.product.stock ? this.product.stock.is_new : null
  },
  methods: {
    updateChanges() {
      this.changes = {
        product_id: this.product.id,
        available_stock: this.available_stock,
        default_quantity: this.default_quantity,
        is_enabled: this.is_enabled,
        is_new: this.is_new,
      };
      this.$emit('update-stock', this.changes);
    },
  },
};
</script>
