<template>
  <div id="bg-img">
    <div class="row">
      <div class="col-6">
        <div class="position-relative">
          <form class="position-absolute intrepid translate-middle form" @submit.prevent="login">
            <h2 class="text-gray login-admin-text">Login (POS)</h2>
            <div class="mb-2">
              <label for="email" class="form-label text-gray label-font">Email</label>
              <input
                type="email"
                class="form-control form-input"
                name="email"
                placeholder="xyz@gmail.com"
                required
                @keyup="checkEmail"
                :class="[email.length > 2 ? statusEmail : '']"
                v-model="email"
              />
              <div class="invalid-feedback">{{ messageEmail }}</div>
            </div>
            <div class="bottom-space">
              <label for="password" class="form-label text-gray label-font">Password</label>
              <input
                type="password"
                class="form-control form-input"
                name="password"
                placeholder="Password"
                required
                v-model="password"
              />
            </div>
            <button
              type="submit"
              class="form-control form-input sign-in submit"
              :isLoading="getLoading"
            >
              Sign in
            </button>
          </form>

          <img :src="require(`@/assets/images/bg-logo.png`)" class="logo" alt="Logo Image" />
          <img :src="require(`@/assets/images/lines.png`)" class="lines" alt="Lines Image" />
          <img :src="require(`@/assets/images/waves.png`)" class="waves-bottom" alt="Waves Image" />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <div class="rectangular-div">
          <ul class="rectangular-div-isting text-gray intrepid">
            <li>.Real-time sales tracking to optimize business performance.</li>
            <li>Customizable reports for insights into sales trends.</li>
            <li>Inventory management made simple and efficient.</li>
            <li>Seamless integration with multiple payment methods.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapActions, mapGetters } from 'vuex';
import '@/assets/css/style.css';
export default {
  name: 'Login',
  mixins: [mixins],
  data() {
    return {
      email: '',
      password: '',
      messageEmail: '',
      statusEmail: '',
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    clearRedirect() {
      this.email = '';
      this.password = '';
      this.statusEmail = '';
      this.messageEmail = '';
      this.$router.push({ name: 'Home' });
    },
    checkEmail() {
      // eslint-disable-next-line no-useless-escape
      const isTrue = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
      if (isTrue) {
        this.statusEmail = '';
      } else {
        this.statusEmail = 'is-invalid';
        this.messageEmail = 'Invalid email';
      }
    },
    login() {
      const dataLogin = {
        email: this.email,
        password: this.password,
      };
      this.loginUser(dataLogin)
        .then((response) => {
          this.$router.push({ name: 'Home' });
          this.toastSuccess(response.message);
        })
        .catch((error) => {
          this.toastError(error.message);
          this.password = '';
        });
    },
  },
  computed: {
    lihatStatus() {
      return this.$store.state.auth.statusLogin;
    },
    ...mapGetters(['getLoading']),
  },
};
</script>
